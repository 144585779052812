<template>
	<div class="p-grid p-mt-4" v-if="user">
    <div class="p-col-12 p-md-12 p-lg-6">
      <Panel header="Общие данные">
        <table>
          <tbody style="text-align: left">
            <tr>
              <th class="p-p-1">Status:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.status">{{ Statuses.find(s => s.num === user.status)?.label[$i18n.locale] }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Type:</th>
              <td class="p-p-1">
<!--                <span class="p-ml-3">{{ user.type === 1 ? 'Private person' : 'Company' }}</span>-->
                <span class="p-ml-3" v-if="user.type">{{ CustomerTypes.find(t => t.type === user.type)?.label[$i18n.locale] }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Role') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.role }}</span>
              </td>
            </tr>
            <tr v-show="user.type === constants.userTypes.legal_entity">
              <th class="p-p-1">{{ $t('Company') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.company_name || '-' }}</span>
              </td>
            </tr>
            <tr v-show="user.type === constants.userTypes.legal_entity">
              <th class="p-p-1">{{ $t('Reg. number') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.reg_number || '-' }}</span>
              </td>
            </tr>
            <tr v-show="user.type === constants.userTypes.legal_entity">
              <th class="p-p-1">{{ $t('VAT number') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.vat_number || '-' }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Name:</th>
              <td class="p-p-1">
                <div class="p-d-flex p-ai-center p-flex-wrap p-ml-3">
                  <CustomerStatusHistoryButton
                      :customerStatus="user.customerStatus"
                      :customerId="user.id"
                      @change-customer-status="changeCustomerStatus"/>
                  <div class="p-mr-1">{{ user.first_name || '' }} {{ user.last_name || '' }}</div>
<!--                  <span style="top: -1px; transform: scale(0.45)" :class="'flag&#45;&#45;datatable flag flag-' + user.flag_code"></span>-->
                  <div class="customer-data-flag-wrapper">
                    <span :class="'flag flag-' + user.flag_code"></span>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Phone') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.type === constants.userTypes.legal_entity && user.company_name"><span v-if="user.company_phone_code">+</span>{{ user.company_phone_code || '' }} {{ user.company_phone_number || '' }}</span>
                <span class="p-ml-3" v-else><span v-if="user.phone_code">+</span>{{ user.phone_code || '' }} {{ user.phone_number || '' }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Email') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.type === constants.userTypes.legal_entity && user.company_name">{{ user.company_email || '' }} </span>
                <span class="p-ml-3" v-else>{{ user.email || '' }}</span>
              </td>
            </tr>
            <tr v-if="user.invoice_email">
              <th class="p-p-1">Invoice email:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.invoice_email }} </span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Address') }}:</th>
              <td class="p-p-1">
                <div v-if="user.address" class="p-ml-3">
                  <span class="p-mr-1" v-show="user.address.address">{{ user.address.address }},</span>
                  <span class="p-mr-1" v-show="user.address.address2">{{ user.address.address2 }},</span>
                  <span class="p-mr-1" v-show="user.address.city">{{ user.address.city }},</span>
                  <span class="p-mr-1" v-show="user.address.state">{{ user.address.state }},</span>
                  <span class="p-mr-1" v-show="user.address.postcode">{{ user.address.postcode }},</span>
                  <span class="p-mr-1" v-if="user.address.country">{{ user.address.country.name }} </span>
                </div>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Created') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.created_at">{{ formatDate(user.created_at) }}<span v-if="user.creator">, {{ user.creator.first_name }} {{ user.creator.last_name }}</span></span>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
    </div>

    <div class="p-col-12 p-md-12 p-lg-6">
      <Panel header="Другая информация">
        <table>
          <tbody style="text-align: left">
<!--            <tr v-if="$store.state.branchData.use_client_card === 1">-->
            <tr>
              <th class="p-p-1">Balance:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ formatCurrency(+user.balance || 0) }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Credit limit:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.type === constants.userTypes.legal_entity && user.company_name">{{ formatCurrency(+user.credit_limit || 0) }}</span>
                <span class="p-ml-3" v-else>-</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Days to pay:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.days_to_pay || 0 }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Services discount:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.services_discount || 0 }}%</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Products discount:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.products_discount || 0 }}%</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">Default tax:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.tax">{{ user.tax.value || 0 }}%</span>
                <span class="p-ml-3" v-else>-</span>
              </td>
            </tr>
            <tr v-if="$store.state.branchData.use_client_card">
              <th class="p-p-1">{{ $t('Client card') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.client_card || '-' }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Birthdate') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3" v-if="user.type === constants.userTypes.legal_entity && user.company_name">-</span>
                <span class="p-ml-3" v-else-if="user.birthdate">{{ formatDay(user.birthdate) }}</span>
              </td>
            </tr>
            <tr>
              <th class="p-p-1">{{ $t('Comment') }}:</th>
              <td class="p-p-1">
                <span class="p-ml-3">{{ user.comment }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>
    </div>
  </div>


</template>

<script>
// import httpClient from '@/services/http.services'
import settings from '@/settings.js'
import constants from '@/constants'
import formatMixins from '@/mixins/formatMixins'
import CustomerStatusHistoryButton from "@/pages/users/components/CustomerStatusHistoryButton";
import CustomerTypes from "@/translations/CustomerTypes";
import Statuses from "@/translations/states/Statuses";

export default {
  components: { CustomerStatusHistoryButton },
  mixins: [ formatMixins ],
  emits: ['change-customer-status'],
  // components: {  },
  props: {
    user: Object,
  },
	data() {
		return {
      userId: null,
      isLoading: true,
      // user: null,
      settings,
      constants,
      CustomerTypes,
      Statuses
		}
	},
  methods: {
    changeCustomerStatus(newStatus) {
      this.$emit('change-customer-status', newStatus)
    }
  }
}
</script>