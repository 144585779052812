export default [
    {
        num: 1,
        label: {
            en: 'Active',
            et: 'Aktiivne',
            ru: 'Активный',
        },
        color: 'green'
    },
    {
        num: 0,
        label: {
            en: 'Not Active',
            et: 'Pole aktiivne',
            ru: 'Не активный',
        },
        color: 'red'
    },
]