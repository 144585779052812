export default [
    {
        type: 1,
        label: {
            en: 'Private person',
            et: 'Eraisik',
            ru: 'Частное лицо'
        },
    },
    {
        type: 2,
        label: {
            en: 'Company',
            et: 'Ettevõte',
            ru: 'Компания'
        },
    },
]